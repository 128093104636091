body {
    margin: 0;
    font-family: "Fira Sans";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f6fa;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    color: inherit;
    text-decoration: inherit;
}

.link {
    color: #424242;
    text-decoration: underline;
}

.link:hover {
    opacity: 0.8;
}

.ar-container {
    margin-top: 2em;
}

.ar-container > * {
    padding: 1em;
    margin: 0.5em;
}

.ar-item-url {
    margin: 1em;
    padding: 1em;
    cursor: pointer;
    text-align: center;
}

.ar-item-url > * {
    color: #424242;
    margin: 0;
}

.ar-info > * {
    margin: 0.6em !important;
    width: 100%;
    text-align: center;
}

.ar-water-mark {
    text-align: center;
}

.ar-water-mark > img {
    padding: 10px;
    position: absolute;
    right: 0;
    width: 5vw;
}

.ar-class-unit {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
}

.ar-class-unit > img {
    height: auto;
    min-height: 225px;
    width: 225px;
}

.ar-class-unit .ar-class-data {
    width: 100%;
}

.ar-class-unit .ar-class-data > * {
    margin: 0.5em;
}

.cardFloat {
    position: fixed;
    bottom: 8px;
    left: 8px;
    z-index: 5000;
    padding: 0;
    margin-bottom: -5px;
}

.cardFloat > iframe {
    transition: width 1s, height 1s;
}

.cardFloat:hover > iframe {
    width: 80vw;
    height: 45vw;
}

.pointer {
    cursor: pointer;
}

.noCursor {
    cursor: inherit !important;
}

.widthInput {
    width: 180px;
}

.fade {
    transition: all 0.1s linear;
}

.fade:hover {
    opacity: 0.7;
}

.capitalize {
    text-transform: capitalize;
}

.danger {
    color: #bd1919;
}

.dashed {
    border: 1px dashed #a8a8a8;
    margin: 8px 0;
    padding: 10px;
}

.close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    cursor: pointer;
}

.marginTop8 {
    margin-top: 8px !important;
}

.marginBottom8 {
    margin-bottom: 8px !important;
}

.marginRight8 {
    margin-right: 8px !important;
}

.margin8 {
    margin: 8px !important;
}

.padding8 {
    padding: 8px !important;
}

.relative {
    position: relative !important;
}

.overflowX {
    overflow-x: auto !important;
}

/* Remove margin from Accordion */
.MuiAccordionSummary-content {
    margin: 0 !important;
}

.button-container {
    margin-bottom: 8px;
    margin-left: 0 !important;
}

.atividades-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

/* File picker */
.guZdik {
    max-width: 100% !important;
}

.stack {
    width: 100% !important;
    padding: 5px 10px;
}

.width100 {
    width: 100% !important;
}

.maxWidth100 {
    max-width: 100% !important;
}

.auth-template {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    justify-content: center;
    height: 100%;
    width: 100% !important;
    max-width: none !important;
}

.auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E9E9E9;
    width: 100%;
    height: 100%;
    padding: 20px 0;
}

.marginTop0 {
    margin-top: 0;
}

.bold {
    font-weight: bold !important;
}

.move {
    cursor: move;
}

.floating {
    max-width: calc(100vw - 20px);
    -webkit-box-shadow: 5px 4px 22px 4px rgba(168,168,168,0.77);
    -moz-box-shadow: 5px 4px 22px 4px rgba(168,168,168,0.77);
    box-shadow: 5px 4px 22px 4px rgba(168,168,168,0.77);
}

.icon-renderer{
    border-bottom: solid 1px #a0a0a0;
}
.icon-renderer:before{
    content: '[';
    color: #a0a0a0;
    font-weight: bold;
}
.icon-renderer:after{
    content: ']';
    color: #a0a0a0;
    font-weight: bold;
}

.r6o-annotation {
    z-index: 5 !important;
}

.thead-bold tr th {
    font-weight: bold;
}

.form h2 {
    margin-top: 15px;
}

.border-top-bottom {
    padding: 10px 0;
    border-top: 1px solid #a8a8a8;
    border-bottom: 1px solid #a8a8a8;
}