*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
}

.name {
    font-size: 1rem;
}

.selected {
    background-color: grey;
    color: white;
}

.selected .inputEntry {
    color: white;
}

.inputEntry {
    width: auto;
    border: none;
    background: none;
    cursor: inherit;
}
