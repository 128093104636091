#html {
    font-family: "Roboto", serif;
    background-color: grey;
    font-size: 21px;
}

#html #fontebd {
    text-align: center;
    font-size: 24px;
    font-style: italic;
}

#html #fontebd p {
    color: white;
}

#html #fontebd a {
    color: white;
    text-decoration: underline;
}

#html #text {
    max-width: 50em;
    background-color: white;
    padding: 5em;
    border: solid 2px black;
    margin: 1em auto;
}

#html #text p {
    text-align: justify;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: -.003em;
}

#html #text p.align-center {
    text-align: center;
}

#html #text p.align-right {
    text-align: right;
}

#html #text p.margin-after {
    padding-bottom: 1em;
}

/*
  22 pixels: with this line height, the annotation icons don't make lines
  bigger.

div#text{
    line-height: 22px;
}
*/

@media screen and (max-width: 768px) {
    #html #text {
        padding: 2em;
    }
}
