.reply {
    border: 1px solid;
    margin: 5px 0;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.zIndexPopup {
    z-index: 3;
}